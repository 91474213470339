/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/layingout';
import SEO from '../components/seo';
import VvVideo from '../assets/videos/vv-new.mp4';
import GridBlock from '../components/gridBlock';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import ImgDisplay from '../components/imgDisplay';
import TopMenu from '../components/topMenu';
import Cars from '../components/cars';
import { Link } from "gatsby"
import Image from "../components/image"
import ImgLogoDisplay from '../components/imgLogoDisplay';

const blockBg = img =>
  `url(${img}) center / cover no-repeat fixed padding-box content-box black`;

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query connectTwoSearch {
      whiterLogo: file(relativePath: { eq: "VV.png" }) {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
           }
        }
      }
          grow: file(relativePath: { eq: "cars/GROW.jpg" }) {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
  connect: file(relativePath: { eq: "cars/CONNECT.jpg" }) {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
  focus: file(relativePath: { eq: "cars/FOCUS.jpg" }) {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const blockCSS = 'flex flex-auto group w-full relative h-1/8 border-0';
  const colorCSS =
    'transition h-full w-full absolute opacity-10 group-hover:opacity-30';
  return (
      
    <Layout to="../why" label="WHY VIVO VIBE" showMenuIcon className="">
      <SEO title="Vivo Vibe" />
      <TopMenu image={data.whiterLogo}></TopMenu>
      <section className="h-screen w-screen">
        <div className="w-screen h-screen fixed">
          <video
            autoPlay
            muted
            playsInline
            loop
            className="object-cover h-full w-full fixed"
          >
            <source src={VvVideo} type="video/mp4" />
          </video>
          <div className="w-full h-full overlay fixed"></div>
        </div>
        <div className="relative flex h-screen w-screen flex-col fourUpPad max-thirteenfifty">
            <div class="pt-16 sm:pt-32 text-center text-2xl pFix vivo-cont minussixt">
                Why Vivo Vibe?
            </div>
      
      <div class="pt-5 md:pt-5 grid grid-cols-1 md:grid-cols-2 gap-4 text-white text-left">

        <div class="col-span-1 vivo  mr-10 parr bg-black bg-opacity-50">
            <div class="py-4 pl-16  pr-10">
                <h4 class="grow">INVESTMENT IN YOU!</h4>
                <div class="doit">Vivo Vibe is the culmination of over 20 years of industry leading experience & dramatic success in Network Marketing.  We are incentivized to help you learn, grow, and succeed.</div>                 
            </div>  

        </div>
      
        <div class="col-span-1 vivo mr-10  parr nu-left bg-black bg-opacity-50">
            <div class="py-4 pl-16  pr-10">
                <h4 class="connect">STRATEGIES FOR SUCCESS</h4>
                <div class="doit">Vivo Vibe employs proven network marketing growth strategies to help ensure your success - both in business and in life. It is a team, a plan, and an encouragement.</div>
            </div>
        </div>

        <div class="col-span-1 vivo mr-10  parr bg-black bg-opacity-50">
            <div class="py-4 pl-16  pr-10">
                <h4 class="focus">A PROVEN PRODUCT</h4>
                <div class="doit">Vivo Vibe has chosen Young Living essential oils because it is 25 years young and is the established best in industry for natural wellness.</div>   
            </div>
        </div>
        
        <div class="col-span-1 vivo  mr-10 parr nu-left bg-black bg-opacity-50">
            <div class="py-4 pl-16  pr-10">
                <h4 class="improve">THE VIVO LIFE!</h4>
                <div class="doit">Vivo Vibe is you! You are what makes it great!  With an expanding group of people who work independently, but grow together, you’ll be part of something truly special.</div>
            </div>
        </div>
      </div>
<div class="pt-10 text-center text-2xl pFix px-20 vivo text-white minussixt">
                Vivo Vibe is a partnership, a plan, and a platform so that you can:
            </div>
      
      <div class="pt-4 pl-10 pr-10 grid text-center grid-cols-1 md:grid-cols-3 gap-4 text-white text-left">

        <div class="col-span-1 vivo mr-10 parr bg-fun-900 bg-opacity-50">
            <AniLink paintDrip to="../why-one" hex="#4299e1">
                <div class="py-4">
                    Connect<br />          
                </div>  
            </AniLink> 
        </div>
      
        <div class="col-span-1 vivo mr-10  parr bg-research-900 bg-opacity-50">
            <AniLink paintDrip to="../why-three" hex="#4299e1">
                <div class="py-4">
                Grow<br />
                </div>
            </AniLink>
        </div>

        <div class="col-span-1 vivo mr-10  parr bg-winner-900 bg-opacity-50">
            <AniLink paintDrip to="../why-two" hex="#4299e1">
                <div class="py-4">
                Focus<br />
                </div>
            </AniLink>
        </div>
      </div>
       <AniLink paintDrip to="../connect" hex="#4299e1">
     <div class="pt-10 text-center text-2xl pFix vivo text-white minussixt">
JOIN TODAY and Experience the Vivo Vibe Life</div>
                  </AniLink>

      </div>
      </section>

      </Layout>
  );
};

export default IndexPage;
